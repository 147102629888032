<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 pb-3">
                    <h3 class="d-inline">#訪問看護報告書</h3>
                    <h6 class="d-inline pl-2" v-if="person">
                        {{ person.relationships.facility.attributes.name }} / {{ person.attributes.name }}様
                    </h6>
                </div>
            </div>
            <div class="row d-flex mb-2">
                <div class="col-2">
                    <select class="custom-select" v-model="form.year" @change="loadDoctorReports">
                        <option :value="year" :selected="form.year === year" v-for="year in years">{{ year }}年</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <home-nursing-report-list :doctorReports="doctorReports"
                                              :person="person"
                                              :year="form.year"
                                              @doctor-report-has-created="createDoctorReport"
                                              @doctor-report-has-updated="updateDoctorReport">
                    </home-nursing-report-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import HomeNursingReportList from "@/components/HomeNursingReports/HomeNursingReportList/HomeNursingReportsList";
import VSelect from "@/components/Forms/VSelect";

export default {
    name: "HomeNursingReports",
    components: {VSelect, HomeNursingReportList},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isSelectTarget: false,
            person: null,
            doctorReports: {
                1: {},
                2: {},
                3: {},
                4: {},
                5: {},
                6: {}
            },
            startYear: '2020',
            addFromThisYear: 5,
            years: [],
            form: {
                year: '2021'
            }
        }
    },
    created: function () {
        this.loadDoctorReports();
        this.loadPerson();
        this.createYearOptions();
    },
    methods: {
        loadDoctorReports: function () {
            this.initDoctorReports();

            axios.get(this.routes.doctorReports, {
                params: {
                    person_id: this.$route.params['id'],
                    year: this.form.year
                }
            })
                .then(response => {
                    this.separateDoctorReports(response.data.data);
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        loadPerson: function () {
            axios.get(this.routes.person(this.$route.params['id']))
                .then(response => {
                    this.person = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        createYearOptions: function () {
            this.years = [];
            let start = this.$moment(this.startYear);
            let end = this.$moment().add(this.addFromThisYear,'years');

            for(let year = start; year <= end; year.add(1,'years')) {
                this.years.push(year.format('YYYY'));
            }
        },
        initDoctorReports: function () {
            this.doctorReports[1] = {};
            this.doctorReports[2] = {};
            this.doctorReports[3] = {};
            this.doctorReports[4] = {};
            this.doctorReports[5] = {};
            this.doctorReports[6] = {};
        },
        separateDoctorReports: function (reports) {
            reports.forEach(report => {
                this.doctorReports[report.attributes.bimonthly_number] = report;
            });
        },
        createDoctorReport: function ($event) {
            this.doctorReports[$event.attributes.bimonthly_number] = $event;
        },
        updateDoctorReport: function ($event) {
            this.doctorReports[$event.attributes.bimonthly_number] = $event;
        }
    }

}
</script>

<style scoped>

</style>
