<template>
    <CModal
        title="訪問看護報告書 更新"
        color="dark"
        size="lg"
        :show.sync="isOpened">
        <div class="container-fluid p-3">
            <div class="row">
                <div class="col-xl-12">
                    <form>
                        <div class="form">
                            <v-input label="利用者氏名"
                                     id="edit-name"
                                     type="text"
                                     disabled
                                     :value="name">
                            </v-input>
                            <v-input label="生年月日"
                                     id="edit-birthday"
                                     type="text"
                                     disabled
                                     :value="birthday">
                            </v-input>
                            <v-input label="年齢"
                                     id="edit-age"
                                     type="text"
                                     disabled
                                     unit="歳"
                                     :value="getAge(birthday)">
                            </v-input>
                            <v-input label="要介護・障害認定の状況"
                                     id="edit-status"
                                     type="text"
                                     disabled
                                     :value="remark">
                            </v-input>
                            <v-input label="住所"
                                     id="edit-address"
                                     type="text"
                                     disabled
                                     :value="address">
                            </v-input>
                            <v-input label="報告書日付"
                                     id="edit-regist-date"
                                     type="date"
                                     :text="registDateText"
                                     :errors="hasErrors('regist_date')"
                                     v-model="form.regist_date">
                            </v-input>
                            <v-input label="医療機関名"
                                     id="edit-hospital"
                                     type="text"
                                     v-model="form.hospital_name"
                                     :errors="hasErrors('hospital_name')"
                                     placeholder="入力必須">
                            </v-input>
                            <v-text-area id="edit-medical-condition"
                                         label="病状の経過"
                                         v-model="form.progress"
                                         :errors="hasErrors('progress')"
                                         placeholder="入力してください">
                            </v-text-area>
                            <v-text-area id="edit-nursing-detail"
                                         label="看護の内容"
                                         v-model="form.care_detail"
                                         :errors="hasErrors('care_detail')"
                                         placeholder="入力してください">
                            </v-text-area>
                            <v-text-area id="edit-matter"
                                         label="特筆すべき事項"
                                         v-model="form.remark"
                                         :errors="hasErrors('remark')"
                                         placeholder="入力してください">
                            </v-text-area>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="updateDoctorReport" :disabled="sending">登録</button>
        </template>
    </CModal>
</template>

<script>
import VTextArea from "@/components/Forms/VTextArea";
import VInput from "@/components/Forms/VInput";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import Helpers from "@/components/Systems/Helpers";

export default {
    name: "EditHomeNursingReportModal",
    components: {VInput, VTextArea},
    mixins: [ApiRouter, ErrorHandler, Helpers],
    props: ['year'],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            name: '',
            birthday: '',
            remark: '',
            address: '',
            id: '',
            form: {
                person_id: '',
                regist_date: '',
                hospital_name: '',
                progress: '',
                care_detail: '',
                remark: '',
                start_date: '',
                end_date: ''
            }
        }
    },
    methods: {
        openModal: function (person, report, index) {
            this.isOpened = true;
            this.initForm(person, report, index);
            this.clearErrors();
        },
        initForm: function (person, report, index) {
            this.name = person.attributes.name;
            this.birthday = person.attributes.birthday;
            this.remark = person.attributes.remark;
            this.address = person.attributes.address;
            this.id = report.id;
            this.form.person_id = person.id;
            this.form.regist_date = report.attributes.regist_date;
            this.form.hospital_name = report.attributes.hospital_name;
            this.form.progress = report.attributes.progress;
            this.form.care_detail = report.attributes.care_detail;
            this.form.remark = report.attributes.remark;
            this.form.start_date = this.getStartDate(index);
            this.form.end_date = this.getEndDate(index);
        },
        getStartDate: function (index) {
            return new Date(this.year, index * 2 - 2, 1);
        },
        getEndDate: function (index) {
            return  new Date(this.year, index * 2, 0);
        },
        updateDoctorReport: function () {
            this.sending = true;
            this.showIndicator('更新中');

            axios.patch(this.routes.doctorReport(this.id), this.form)
                .then(response => {
                    this.$emit('doctor-report-has-updated', response.data.data);
                    this.showSuccessPopup('更新しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    },
    computed: {
        registDateText: function () {
            if (this.form.start_date === '' || this.form.end_date === '') {
                return '';
            }

            return '報告書日付は ' + (this.form.start_date.getMonth() + 1) + '月から'
                + (this.form.end_date.getMonth() + 1) + '月の日付を入力してください';
        }
    }
}
</script>

<style scoped>

</style>
