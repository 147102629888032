<template>
    <div>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <th></th>
            <th>作成者</th>
            <th>作成日</th>
            <th>最終更新者</th>
            <th>最終更新日</th>
            <th class="text-center">表示</th>
            <th class="text-center">編集</th>
            </thead>
            <tbody>
                <template v-for="(doctorReport, index) in doctorReports">
                    <tr v-if="Object.keys(doctorReport).length > 0">
                        <td class="bg-dark">{{ getBimonthly(index) }}</td>
                        <td>{{ doctorReport.attributes.creator_name }}</td>
                        <td>{{ doctorReport.attributes.regist_date | moment('YYYY年MM月DD日') }}</td>
                        <td>{{ doctorReport.attributes.editor_name }}</td>
                        <td>{{ doctorReport.attributes.updated_at | moment('YYYY年MM月DD日') }}</td>
                        <td class="text-center">
                            <a href="javascript:void(0)" @click="next(doctorReport)">表示</a>
                        </td>
                        <td class="text-center"><a href="javascript:void(0)" @click="openEditModal(doctorReport, index)">編集</a></td>
                    </tr>
                    <tr v-else>
                        <td class="bg-dark">{{ getBimonthly(index) }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colspan="2" class="text-center"><a href="javascript:void(0)" @click="openCreateModal(index)">作成</a></td>
                    </tr>
                </template>
            </tbody>
        </table>
        <create-home-nursing-report-modal ref="createModal"
                                          :year="year"
                                          @doctor-report-has-created="$emit('doctor-report-has-created', $event)">
        </create-home-nursing-report-modal>
        <edit-home-nursing-report-modal ref="editModal"
                                        :year="year"
                                        @doctor-report-has-updated="$emit('doctor-report-has-updated', $event)">
        </edit-home-nursing-report-modal>
    </div>
</template>

<script>
import EditHomeNursingReportModal
    from "@/components/HomeNursingReports/EditHomeNursingReportModal/EditHomeNursingReportModal";
import CreateHomeNursingReportModal
    from "@/components/HomeNursingReports/CreateHomeNursingReportModal/CreateHomeNursingReportModal";

export default {
    name: "HomeNursingReportList",
    components: {CreateHomeNursingReportModal, EditHomeNursingReportModal},
    props: ['doctorReports', 'person', 'year'],
    methods: {
        openCreateModal: function (index) {
            this.$refs['createModal'].openModal(this.person, index);
        },
        openEditModal: function (report, index) {
            this.$refs['editModal'].openModal(this.person, report, index);
        },
        next: function (report) {
            this.$router.push({ name: 'DisplayHomeNursingReport', params: { id: report.id } })
        },
        getBimonthly: function (number) {
            switch (number) {
                case '1':
                    return '1月・2月';
                case '2':
                    return '3月・4月';
                case '3':
                    return '5月・6月';
                case '4':
                    return '7月・8月';
                case '5':
                    return '9月・10月';
                case '6':
                    return '11月・12月';
                default:
                    return '';
            }
        }
    }
}
</script>

<style scoped>

</style>
